import { APP_STORE_URL, PLAY_STORE_URL } from 'config';
import { NextPage } from 'next';
import React, { useEffect, useMemo } from 'react';
import PlatformService from 'services/platform';

import { Container, Content, DeltaLogo, Link, RedirectText } from './styles';

interface Props {
  universalLinkPath: string;
}

const UniversalLinks: NextPage<Props> = (props) => {
  const downloadUrl = useMemo(() => {
    if (PlatformService.isIos) {
      return APP_STORE_URL;
    } else if (PlatformService.isAndroid) {
      return PLAY_STORE_URL;
    } else {
      return 'https://delta.app/download';
    }
  }, []);

  const deeplinkUrl = useMemo(() => {
    return 'io.getdelta://open/' + props.universalLinkPath;
  }, [props.universalLinkPath]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    window.location.replace(deeplinkUrl);
  }, [deeplinkUrl]);

  return (
    <Container>
      <Content>
        <DeltaLogo src="/static/images/delta-logo--white.svg" />
        <RedirectText>Redirecting to Delta app...</RedirectText>
        <Link href={downloadUrl}>
          If you weren&apos;t redirected, click here.
        </Link>
      </Content>
    </Container>
  );
};

UniversalLinks.getInitialProps = async (ctx) => {
  const universalLinkPath =
    ctx.asPath?.replace(/^\/open-delta-app\//, '') ?? '';

  return {
    universalLinkPath,
  };
};

export default UniversalLinks;
