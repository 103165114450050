/* eslint-disable */
export const IOS_APP_ID = '1288676542';
export const ANDROID_APP_ID = 'io.getdelta.android';
export const BRAVE_PROMO_URL = 'https://delta.onelink.me/trnX/6ca6f960';
export const APP_STORE_URL = `https://itunes.apple.com/app/delta-crypto-ico-portfolio/id${IOS_APP_ID}`;
export const PLAY_STORE_URL = `https://play.google.com/store/apps/details?id=${ANDROID_APP_ID}`;
export const META_SITENAME = 'Delta ∆';
export const META_TWITTER = '@get_delta';
export const SUPPORTED_EXCHANGES = 300;
export const IMAGE_SERVICE_URL = process.env.NEXT_PUBLIC_IMAGE_SERVICE;
export const IMAGE_SERVICE_PATH = process.env.NEXT_PUBLIC_IMAGE_SERVICE;
export const BASE_URL = 'https://delta.app';
export const APPS_FLYER_APP_STORE_IOS_URL =
  'https://app.appsflyer.com/id1288676542?pid=atm.com_int&c={c}&af_siteid={publisher_id}&af_ad={af_ad}&af_ad_id={af_ad_id}&af_c_id={af_c_id}&af_click_lookback=7d&clickid={clickid}&advertising_id={gaid}&idfa={idfa}&is_incentivized={is_incentivized}&is_retargeting={is_retargeting}';
export const APPS_FLYER_PLAY_STORE_ANDROID_URL =
  'https://app.appsflyer.com/io.getdelta.android?pid=atm.com_int&c={c}&af_siteid={publisher_id}&af_ad={af_ad}&af_ad_id={af_ad_id}&af_c_id={af_c_id}&af_click_lookback=7d&clickid={clickid}&advertising_id={gaid}&idfa={idfa}&is_incentivized={is_incentivized}&is_retargeting={is_retargeting}';
